import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import {
  NewsFeedListItemWrapper,
  NewsFeedItem,
} from '@fuww/library/src/List/NewsList';
import breakpoints from '@fuww/library/src/Theme/breakpoints';
import { useIntl } from 'react-intl';
import { RelativeContainer } from '@fuww/library/src/Card';
import LinkAndAnchor from '../../LinkAndAnchor';
import getArticlesParameters from '../../../lib/getArticlesParameters';
import ImageWithUrls from '../../ImageWithUrls';
import FormattedDate from '../../FormattedDate';
import getCategoryTitle from '../../../lib/getCategoryTitle';
import getProxiedImages from '../../../lib/getProxiedImages';
import { useSiteContext } from '../../SiteContext';
import {
  ArticlesConnectionQuery,
  ArticlesConnectionQueryVariables,
  AuthorByEmailQuery,
  AuthorByEmailQueryVariables,
} from '../../../lib/graphql/api/graphql';
import messages from '../../../lib/messages.mjs';

const newsFeedListItemPropertyTypes = {
  variables: PropTypes.shape({}),
  article: PropTypes.shape({}),
  isFirstImage: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool,
};

type ListItemProperties = Omit<InferProps<
typeof newsFeedListItemPropertyTypes
>, 'article'> & {
  article?: ArticlesConnectionQuery[
    'universalNewsArticlesConnection']['edges'][number]['node'] |
  NonNullable<AuthorByEmailQuery['universalAuthorByEmail']>[
    'newsArticlesConnection']['edges'][number]['node'];
  variables: ArticlesConnectionQueryVariables | AuthorByEmailQueryVariables;
};

const NewsFeedListItem = ({
  article,
  isFirstImage,
  variables,
  showLoader,
}: ListItemProperties) => {
  const intl = useIntl();
  const { hasLocalNews } = useSiteContext();

  const {
    title,
    imageUrls: originalImageUrls,
    category,
    insertedAt,
    path,
    pressRelease,
    partnerContent,
  } = {
    title: null,
    imageUrls: [],
    category: null,
    insertedAt: null,
    path: null,
    pressRelease: null,
    partnerContent: null,
    ...article,
  };

  const { imageUrls, srcSet }: {
    imageUrls?: (string | null)[];
    srcSet?: string;
  } = showLoader ? {} : getProxiedImages(
    variables, originalImageUrls,
  );
  const fetchpriority = isFirstImage ? 'high' : 'low';

  return (
    <NewsFeedListItemWrapper className="fu-list-item-content">
      <LinkAndAnchor
        {...showLoader ? {
          href: '#',
        } : {
          route: 'article',
          params: getArticlesParameters(article),
          useRouter: true,
          href: path ?? undefined,
        }}
      >
        <NewsFeedItem
          article={article}
          date={<FormattedDate value={insertedAt} />}
          category={category && getCategoryTitle(
            category, hasLocalNews, intl,
          )}
          isPressRelease={pressRelease}
          isPartnerContent={partnerContent}
          image={imageUrls?.[0] && title && !showLoader && srcSet && (
            <RelativeContainer>
              <ImageWithUrls
                imageUrls={imageUrls.filter(Boolean) as string[]}
                alt={title}
                width="100%"
                height="auto"
                sizes={`
                (min-width: ${breakpoints.sm}px) 201px,
                36vw
              `}
                objectFit="cover"
                srcSet={srcSet}
                preload={isFirstImage}
                finalFetchpriority={fetchpriority}
                placeholderFetchpriority={fetchpriority}
              />
            </RelativeContainer>
          )}
          showLoader={showLoader}
          memberAccessLabelText={intl.formatMessage(messages.member)}
        />
      </LinkAndAnchor>
    </NewsFeedListItemWrapper>
  );
};

NewsFeedListItem.propTypes = newsFeedListItemPropertyTypes;

export default NewsFeedListItem;
